<template>
    <preview-container
        :ad-data="adData"
        @show-play-buttons="showPlayButtons"
        @hide-play-buttons="hidePlayButtons">
        <div class="image-holder">
            <product
                v-if="product"
                :ad-data="adData"
                :product="product" />
            <img
                v-else-if="assetType === 'IMAGE' || assetType === 'COLLECTION'"
                :key="mediaUrl"
                class="preview-image"
                :src="mediaUrl">
            <div
                v-if="controlsActive && assetType === 'VIDEO'"
                class="play-wrapper"
                @click="playVideo">
                <div class="play">
                    <icon
                        v-if="!hidePlayIcon"
                        size="40"
                        color="white"
                        name="audio-play" />
                    <div v-else>
                        <div class="previews-pause-bar" />
                        <div class="previews-pause-bar" />
                    </div>
                </div>
            </div>
            <video-player
                v-if="assetType === 'VIDEO'"
                ref="video"
                :controls="false"
                :muted="muted"
                :src="mediaUrl"
                color="white"
                class="preview-video" />
            <div class="info-block">
                <div class="info-block-image">
                    <img
                        :src="pageLogo">
                </div>
                <div class="info-block-content">
                    <div class="info-block-title">
                        {{ sanitizedPageName }}
                    </div>
                    <div class="info-block-subtitle">
                        {{ sanitizedHeadlineText }}
                    </div>
                    <div class="info-block-url">
                        {{ hostname }}
                    </div>
                </div>
                <div class="info-block-arrow">
                    <icon
                        name="arrow-left"
                        color="white"
                        size="15" />
                </div>
            </div>
        </div>
        <div class="bottom-desc">
            Ad
        </div>

        <div class="bottom-block">
            <div class="bottom-dash" />
            <div class="bottom-visit">
                <a
                    :href="ctaLink"
                    target="_blank">
                    {{ ctaText }}
                </a>
            </div>
            <div class="bottom-icon bottom-left-icon">
                <icon
                    size="25"
                    color="white"
                    name="snapchat-camera" />
            </div>
            <div class="bottom-icon bottom-right-icon">
                <icon
                    size="20"
                    color="white"
                    name="snapchat-arrow" />
            </div>
        </div>
    </preview-container>
</template>

<script>
import PreviewContainer from './PreviewContainer';
import Product from './Product';
import Icon from '@/components/globals/Icon';
import VideoPlayer from '@/components/globals/VideoPlayer/index.vue';
import { mapState, mapGetters } from 'vuex';
import { lengthValidatorConstants } from '@/validators/granular/lengthValidator';
import { SNAPCHAT, headlineKey } from '@/components/ad-studio/store/constants';

export default {
    name: 'SnapchatSinglePreview',
    components: {
        PreviewContainer,
        Icon,
        VideoPlayer,
        Product
    },
    props: {
        adData: {
            type: [Object, Array],
            required: true
        },
        muted: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            hidePlayIcon: false,
            controlsActive: true
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer
        }),
        ...mapGetters([
            'dealerPrimaryLogo'
        ]),
        profileLogo() {
            return this.adData?.platform_specific_fields?.profile?.picture;
        },
        pageLogo() {
            return this.profileLogo || this.dealerPrimaryLogo?.url || '/img/platforms/snapchat-default.webp';
        },
        profileName() {
            return this.adData?.platform_specific_fields?.brand_name ?? this.adData?.platform_specific_fields?.profile?.name;
        },
        sanitizedPageName() {
            const pageName = this.profileName || this.currentDealer.name || this.adData?.platform_specific_fields?.snapchat_page?.name;
            if (pageName.length > 20) {
                const cropped = pageName.substring(0, 20);
                return `${cropped}...`;
            } else {
                return pageName;
            }
        },
        sanitizedHeadlineText() {
            const primaryText = this.adData?.headline || this.creatives?.[0]?.headline || this.adData?.media_items?.[0]?.headline;
            return primaryText?.slice(0, lengthValidatorConstants[headlineKey][SNAPCHAT].maxLength);
        },
        hostname() {
            try {
                if (!this.ctaLink) {
                    return '';
                }

                const url = new URL(this.ctaLink);
                return url.hostname.substring(0, 30);
            } catch (error) {
                return '';
            }
        },
        isDynamic() {
            return this.adData?.render_type === 'DYNAMIC' || this.adData?.products?.length > 1;
        },
        product() {
            if (!this.adData?.products?.length) {
                return null;
            }

            const randomIndex = Math.floor(Math.random() * this.adData.products.length);
            return this.adData?.products?.[randomIndex];
        },
        firstAsset() {
            return this.creatives?.[0]?.assets?.[0];
        },
        mediaUrl() {
            return this.adData?.creatives[0]?.media_url || this.firstAsset?.url || '';
        },
        ctaLink() {
            return this.adData?.cta_link || this.creatives?.[0]?.cta_link || this.link;
        },
        ctaText() {
            const ctaText = this.adData?.cta_text || this.creatives?.[0]?.cta_text || this.adData?.media_items?.[0]?.cta_text;
            return ctaText?.toLowerCase() === 'learn more' ? 'More' : ctaText;
        },
        assetType() {
            return (this.creatives?.[0]?.media_type || this.firstAsset?.asset_type?.name)?.toUpperCase();
        },
        creatives() {
            return this.adData?.creatives ?? [];
        },
        link() {
            if (this.product) {
                return this.product?.link ?? '';
            }

            return this.creatives?.[0]?.cta_link ?? '';
        }
    },
    created() {
        this.checkIfPreviewIsValid();
    },
    methods: {
        checkIfPreviewIsValid() {
            if (this.assetType === 'VIDEO') {
                this.$emit('set-video-type');
            }
            if (!this.isDynamic && !this.mediaUrl) {
                throw new TypeError('This type of ad is not supported!');
            }
        },
        playVideo() {
            if (this.hidePlayIcon) {
                this.$refs.video.togglePlay();
                this.hidePlayIcon = false;
            } else {
                this.$refs.video.togglePlay();
                this.hidePlayIcon = true;
            }
        },
        showPlayButtons() {
            this.controlsActive = true;
        },
        hidePlayButtons() {
            if (this.hidePlayIcon) {
                this.controlsActive = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>

.image-holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 74px;
    display: flex;
    align-items: center;
}

.preview-image {
    width: 100%;
    display: inline-block;
    height: auto;
}

.preview-video {
    width: 100%;
    height: auto;
}

.bottom-block {
    position: absolute;
    z-index: 20;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    text-align: center;
    height: 74px;
}

.bottom-dash {
    width: 35px;
    height: 5px;
    background: $gray-dark;
    margin: 6px auto;
    border-radius: 5px;
}

.bottom-icon {
    position: absolute;
    top: 17px;
    background-color: $gray-dark;
    border-radius: 25px;
    height: 51px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottom-left-icon {
    left: 10px;
}

.bottom-right-icon {
    right: 10px;
}

.bottom-visit {
    color: black;
    display: inline-block;
    width: calc(100% - 160px);
    font-weight: 700;
    border-radius: 25px;
    background: white;
}

.bottom-visit a {
    text-decoration: none;
    color: black;
    display: block;
    font-size: 18px;
    min-height: 51px;
    padding: 12px 0;
}

.bottom-desc {
    position: absolute;
    bottom: 91px;
    right: 10px;
    color: $white;
    z-index: 2;
    font-size: 12px;
    text-shadow: 1px 1px 10px $black;
}

.play-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 3;

    .play {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -20px;
        margin-left: -20px;
        cursor: pointer;
        position: relative;
        z-index: 2;
    }

    &:after {
        content: '';
        position: absolute;
        width: 85px;
        border-radius: 50%;
        border: 1px solid white;
        height: 85px;
        top: 50%;
        left: 50%;
        margin-top: -44px;
        margin-left: -45px;
        background: rgba(0, 0, 0, 0.5);
        cursor: pointer;
        z-index: 1;
    }
}

.info-block {
    position: absolute;
    bottom: 10px;
    left: 15px;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 8px;
    display: flex;
    align-items: center;
}

.info-block-image {
    max-width: 75px;
    max-height: 75px;
    border-radius: 10px;
    overflow: hidden;

    img {
        width: 100%;
        height: auto;
        vertical-align: top;
    }
}

.info-block-content {
    margin-left: 10px;
}

.info-block-title {
    font-weight: bold;
    font-size: 14px;
}

.info-block-subtitle {
    font-size: 12px;
}

.info-block-url {
    font-size: 12px;
}

.info-block-arrow {
    margin-left: 30px;
}
</style>
